/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'

type ApiResponse = {
  updated_at: string
  data: {
    [key: string]: {
      name: string
      symbol: string
      price: string
      price_BNB: string
    }
  }
}

const api = 'https://api.pancakeswap.info/api/v2/tokens'

const useGetPriceData = () => {
  const [data, setData] = useState<ApiResponse | null>(null)

  useEffect(() => {
    // const fetchData = async () => {
    //   try {
    //     const response = await fetch(api)
    //     const res: ApiResponse = await response.json()

    //     setData(res)
    //   } catch (error) {
    //     console.error('Unable to fetch price data:', error)
    //   }
    // }

    // fetchData()

    setData({
      updated_at: new Date().toISOString(),
      data: {
        '0xf3EEbC9ccc329cDf7A38AE7478588DFd66df76E0': {
          name: 'BIF',
          symbol: 'BIF',
          price: '1',
          price_BNB: '10',
        },
      },
    })
  }, [setData])

  return data
}

export default useGetPriceData
